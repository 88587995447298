import { createWebHistory, createRouter } from "vue-router";
import { routes } from "./routes";

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = `${to.meta.title}`;
    } else {
        document.title = 'Конструктор телеграм ботов';
    }
    next();
})

export default router;
