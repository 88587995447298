import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import '@vueform/multiselect/themes/default.css'
import PrimeVue from 'primevue/config';
import StyleClass from 'primevue/styleclass';
import Ripple from 'primevue/ripple';


// vue flow
import "@vue-flow/core/dist/style.css";
import "@vue-flow/core/dist/theme-default.css";
import 'primevue/resources/themes/lara-light-green/theme.css'
import 'primeicons/primeicons.css'
import ToastService from 'primevue/toastservice';
import * as Sentry from "@sentry/vue";
import {createPinia} from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueSmoothScroll from 'vue3-smooth-scroll'
import UUID from "vue-uuid";
import Tooltip from 'primevue/tooltip';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import { quillEditor, Quill } from 'vue3-quill'

import './main.css'
import './main.scss'

window.onerror = function (error) {
    console.log(error)
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

// https://quilljs.com/docs/modules/toolbar
const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block, true);

// const Break = Quill.import('blots/break');
// Break.tagName = 'n';
// Quill.register(Break, true);

const bold = Quill.import('formats/bold');
bold.tagName = 'b';
Quill.register(bold, true);

const italic = Quill.import('formats/italic');
italic.tagName = 'i';
Quill.register(italic, true);

export const app = createApp(App);
app
    .use(router)
    .use(ToastService)
    .use(pinia)
    .use(quillEditor)
    .use(VueSmoothScroll, {
        duration: 400,
    })
    .use(UUID)
    .use(PrimeVue, {
      // ripple: true,
      locale: {
        dayNamesShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        monthNames: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сент","Октябрь","Ноябрь","Декабрь"],
        monthNamesShort:  ["Янв","Фев","Март","Апр","Май","Июнь","Июль","Август","Сент","Октяб","Ноябр","Дек"],
        firstDayOfWeek: 1,
        today: 'Сегодня',
        clear: 'Очистить',
        apply: 'Применить',
        dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
      }
    })

app.directive('styleclass', StyleClass);
app.directive('ripple', Ripple);
app.directive('tooltip', Tooltip);

// Sentry.init({
//     app,
//     dsn: "http://485834f42f5d9b9444872c7fc528e4b2@sentry.ninja-bot.ru/3",
//     integrations: [
//         Sentry.browserTracingIntegration({ router }),
//         Sentry.replayIntegration(),
//     ],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//
//     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//
//     // Capture Replay for 10% of all sessions,
//     // plus for 100% of sessions with an error
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//     environment: "production",
// });

app.mount('#app')